@import "../../styles/variables.scss";

.body {
  margin-left: $spacing-xl;
  margin-top: $spacing-xl;
  margin-bottom: $spacing-xl;
  margin-right: $spacing-xxxl;
  text-align: justify;
  @media (max-width: 768px) {
    margin-top: $spacing-lg;
    margin-left: $spacing-md;
    margin-right: $spacing-lg;
  }

  .title {
    font-size: $font-xlb;
    font-weight: bold;
    margin-bottom: $spacing-sm;
    margin-top: $spacing-xs;
    text-decoration: underline;
    @media (max-width: 768px) {
      font-size: $font-lg;
      margin-top: $spacing-md;
      margin-left: $spacing-xs;
      margin-right: $spacing-lg;
      text-align: left;
    }
  }

  .subbold {
    font-weight: bold;
    text-decoration: underline;
  }
  .description {
    font-size: $font-md;
    margin-bottom: $spacing-ml;
    margin-left: $spacing-sm;
    @media (max-width: 768px) {
      font-size: $font-sm;
      margin-left: $spacing-sm;
      margin-bottom: $spacing-md;
    }
  }

  .list-wrapper {
    margin-bottom: $spacing-md;
  }

  .list-item {
    list-style-type: disc;
    margin-left: $spacing-lg;
    font-size: $font-md;
    @media (max-width: 768px) {
      font-size: $font-sm;
    }
  }
}
