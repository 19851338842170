@import "../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px;
    width: 100%;
    height: max-content;
    background-color: $white;
    //margin-top: $spacing-sm;
    :nth-child(3) {
      padding-right: $spacing-sm;
    }
    height: 100px;
    @media (max-width: 768px) {
      height: 80px;
    }
    .logo {
      object-fit: cover;
      margin-right: auto;
      height: 100%;
      object-fit: contain;
      @media (max-width: 768px) {
        height: 100%;
      }
    }
  }

  .bluebody {
    border: solid 1px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    background-color: $blue;
    height: max-content;
    width: 100%;
    :nth-child(3) {
      padding-right: $spacing-xl;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      :nth-child(3) {
        padding-right: 0;
        padding-bottom: $spacing-sm;
      }
    }
    .extrapadding {
      margin-left: $spacing-md;
    }
    .phone {
      width: 410px;
      object-fit: fill;
      overflow: hidden;
      margin-left: $spacing-ml;
      @media (max-width: 768px) {
        width: 340px;
      }
      @media (min-width: 769px) and (max-width: 850px) {
        width: 390px;
        margin-left: $spacing-sm;
      }
    }
    .axisBody {
      margin-top: $spacing-sm;
      height: 100%;
      width: 33%;
      @media (max-width: 850px) {
        display: none;
      }
    }

    .bluearticle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 400px;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        :nth-child(3) {
          padding-right: 0;
          padding-bottom: $spacing-sm;
        }
      }
      .bluetitle {
        width: max-content;
        font-size: $font-xxl;
        font-weight: 600;
        margin: $spacing-sm $spacing-xs $spacing-xs 0;
        @media (max-width: 768px) {
          font-size: $font-xl;
          margin-top: $spacing-xs;
          width: 85%;
        }
        @media (min-width: 769px) and (max-width: 850px) {
          width: 75%;
        }
      }
      .secondtitle {
        width: max-content;
        font-size: $font-xl;
        font-weight: 600;
        margin: $spacing-sm $spacing-xs $spacing-sm 0;
        @media (max-width: 768px) {
          font-size: $font-lgr;
          margin-top: $spacing-xs;
        }
      }
      .subtitle {
        width: max-content;
        margin: $spacing-sm $spacing-md $spacing-md $spacing-xl;
        font-size: $font-lg;
        .subbold {
          font-weight: bold;
        }
        @media (max-width: 768px) {
          font-size: $font-md;
          margin: $spacing-sm $spacing-md $spacing-md $spacing-md;
          text-align: center;
          width: 80%;
        }
        @media (max-width: 1400px) and (min-width: 769px) {
          font-size: $font-md;
          margin: $spacing-sm $spacing-md $spacing-md $spacing-xxl;
          text-align: center;
          width: 99%;
        }
        @media (max-width: 1200px) and (min-width: 769px) {
          font-size: $font-md;
          margin: $spacing-sm $spacing-md $spacing-md $spacing-md;
          text-align: center;
          width: 99%;
        }
      }
      .login {
        margin: $spacing-xs $spacing-md $spacing-md 0;
        font-size: $font-xs;
        .underline {
          text-decoration: underline;
        }
      }
      .refund {
        margin: $spacing-sm $spacing-md $spacing-md 0;
        font-size: $font-xs;
        .subbold {
          font-weight: bold;
        }
        @media (max-width: 768px) {
          text-align: center;
          width: 70%;
        }
      }
      .bluewhatsapp {
        width: 50px;
      }
      .buttons-wrapper {
        height: 100%;
        width: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 0 0;
        @media (max-width: 768px) {
          margin: 0 $spacing-md 0 0;
        }
        .annually-button {
          display: flex;
          flex-direction: row;
          border-radius: $border-radius;
          border: 2px solid $black;
          width: 270px;
          padding: 1em 0.9em 0em 0.9em;
          font-size: $font-xs;
          background-color: $orange;
          color: $black;

          cursor: pointer;
          text-align: left;
          &:hover {
            color: $white;
          }
          .button-big {
            font-size: $font-md;
            font-weight: bold;
          }
          .buttonbold {
            font-weight: bold;
          }
          .child1 {
            width: 95%;
          }
          .child2 {
            width: 78px;
            text-align: center;

            .savenow {
              border: 2px solid $black;
              padding: 0.5em 0.25em 0.25em 0.25em;
              border-radius: $border-radius;
              background-color: $yellow;
              .subbold {
                font-weight: bold;
              }
              cursor: pointer;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
        .smallspace {
          padding-bottom: $spacing-sm;
        }
        .monthly-button {
          border-radius: $border-radius;
          border: 2px solid $black;
          width: 270px;
          padding: 1em 0 0.25em 0.9em;
          font-size: $font-xs;
          background-color: $purple;
          color: $black;
          cursor: pointer;
          text-align: left;
          &:hover {
            color: $white;
          }
          .button-big {
            font-size: $font-md;
            font-weight: bold;
            text-align: left;
          }
        }

        :nth-child(1) {
          margin-bottom: $spacing-sm;
        }
      }
      :nth-child(3) {
        margin-bottom: $spacing-sm;
      }
    }
  }

  .yellowbody {
    border: solid 1px;
    display: flex;
    flex-direction: row;
    background-color: $yellow;
    height: max-content;
    padding: $spacing-md;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      padding-left: 0.9em;
    }

    .video {
      border-radius: $border-radius;
      border: solid 2px;
      margin: 1.2em;
      //margin: auto;
      height: 27.5rem;
      width: 47%;

      @media (max-width: 768px) {
        width: 100%;
        height: 24rem;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-top: $spacing-sm;
      padding-left: $spacing-sm;

      @media (max-width: 768px) {
        text-align: center;
        width: 100%;
      }

      .yellowtitle {
        padding-top: $spacing-md;
        padding-bottom: $spacing-sm;
        height: max-content;
        width: max-content;
        font-size: $font-xl;
        padding-left: $spacing-sm;
        padding-right: $spacing-md;
        font-weight: 600;
        @media (max-width: 768px) {
          text-align: center;
          width: 100%;
          padding-top: $spacing-sm;
        }
        @media (min-width: 769px) and (max-width: 850px) {
          width: 99%;
          padding-top: $spacing-xs;
          padding-bottom: $spacing-xs;
        }
      }

      .subtitle {
        padding-top: $spacing-md;
        padding-left: $spacing-md;
        font-size: $font-md;
        text-align: left;

        @media (max-width: 768px) {
          padding-left: 0.65em;
          padding-right: $spacing-smd;
          width: 100%;
          padding-top: $spacing-sm;
        }
        @media (max-width: 850px) {
          padding-top: 0;
        }

        .tick {
          color: $black;
          font-weight: bold;
          font-size: $font-lgr;
          // -webkit-text-fill-color: $green;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: black;
        }
        .cross {
          color: $red;
          font-weight: bold;
          font-size: $font-xxl;
          -webkit-text-fill-color: $red;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: black;
        }
        .subbold {
          font-size: $font-lg;
          font-weight: bold;
        }
      }
    }
  }

  .orangebody {
    border: solid 1px;
    display: flex;
    flex-direction: column;
    padding: $spacing-md;
    background-color: $orange;
    height: max-content;
    width: 100%;

    @media (max-width: 768px) {
      align-items: center;
    }
    .title-box {
      display: flex;
      flex-direction: row;
      .orangetitle {
        flex-direction: row;
        height: max-content;
        width: max-content;
        font-size: $font-xl;
        font-weight: 600;
        margin-bottom: $spacing-sm;
        margin-top: $spacing-sm;
        .crown {
          width: 70px;
        }
        @media (max-width: 768px) {
          text-align: center;
          width: 100%;
          font-size: $font-xlb;
        }
      }
    }
    .top-picks-box {
      display: flex;
      flex-direction: row;
      max-width: 100%;
      padding-left: $spacing-xl;
      justify-content: space-evenly;
      margin-top: $spacing-md;

      .pick {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: max-content;
        height: max-content;
        border: solid 2px;
        border-radius: 8px;
        margin-bottom: $spacing-lg;
        //background-color: $white;
        .company-name {
          padding-top: $spacing-sm;
          padding-bottom: 0;
          font-weight: 600;
          font-size: $font-lg;
        }
        .company-description {
          padding-bottom: $spacing-sm;
          font-size: $font-md;
          .boldcompanydescription {
            font-size: $font-xl;
            font-weight: bold;
            color: $black;
            -webkit-text-fill-color: $black;
            -webkit-text-stroke-width: 0.05px;
            -webkit-text-stroke-color: black;
            @media (max-width: 768px) {
              font-size: $font-xl;
            }
          }
          .greenarrow {
            color: $green;
            font-weight: bold;
            font-size: $font-xl;
            font-style: normal;
            -webkit-text-fill-color: $green;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: black;
          }
        }

        .company-logo {
          background-color: white;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom: 2px solid;
          width: 270px;
          height: 170px;
        }
        @media (max-width: 768px) {
          margin-bottom: $spacing-lg;
        }
      }

      @media (max-width: 768px) {
        padding-left: 0;
        flex-direction: column;
      }
    }
  }
  .purplebody {
    border: solid 1px;
    padding: $spacing-lg;
    background-color: $purple;
    justify-content: space-evenly;
    height: max-content;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    .purpletitle {
      height: max-content;
      width: max-content;
      font-size: $font-xl;
      font-weight: 600;
      margin-bottom: $spacing-sm;
      margin-top: $spacing-xs;
      vertical-align: top;
      @media (max-width: 768px) {
        text-align: center;
        font-size: $font-xlb;
      }
      .chattitle {
        width: 75px;
      }
    }
    // .purple-items-box {
    //   display: flex;
    //   flex-direction: row;
    //   max-width: 100%;
    //   // padding-left: $spacing-xs;
    //   justify-content: space-evenly;
    //   margin-top: $spacing-md;

    //   @media (max-width: 768px) {
    //     align-items: center;
    //     flex-direction: column;
    //   }
    //   .purple-item {
    //     display: flex;
    //     flex-direction: column;
    //     text-align: center;
    //     justify-content: space-evenly;
    //     //height: max-content;
    //     margin-top: $spacing-sm;
    //     margin-bottom: $spacing-lg;

    //     width: 400px;
    //     height: 200px;
    //   }
    // }
  }

  .whitebody {
    display: flex;
    border: solid 1px;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: $white;
    height: max-content;
    width: 100%;
    padding: $spacing-xxxl;

    .subtitle {
      padding-top: $spacing-md;

      padding-bottom: $spacing-xl;
      font-size: $font-lg;
      font-weight: bold;
      text-align: center;
      flex-direction: row;
    }
    .separator {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      @media (max-width: 768px) {
        align-items: center;
        flex-direction: column;
      }
    }
    .regulations {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 350px;
      height: 185px;
      margin-bottom: $spacing-lg;
      @media (max-width: 768px) {
        width: 350px;
        height: 120px;
      }
      @media (min-width: 769px) and (max-width: 850px) {
        width: 350px;
        height: 130px;
      }
    }
    .sources {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 350px;
      height: 70px;
      margin-bottom: $spacing-lg;
      @media (max-width: 768px) {
        width: 350px;
        height: 45px;
      }
      @media (min-width: 769px) and (max-width: 850px) {
        width: 350px;
        height: 50px;
      }
    }
  }

  .footer {
    border: solid 1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $blue;
    height: max-content;
    width: 100%;
    padding: $spacing-lg;
    @media (max-width: 768px) {
      // padding: $spacing-sm;
      align-items: center;
      flex-direction: column;
    }

    .logo {
      width: 100px;
      height: 100px;
    }
    /*
    .info {
      display: flex;
      flex-direction: column;
      padding: $spacing-md;
      .text {
        font-size: $font-xs;
        // max-width: 31%;
      }
      @media (max-width: 768px) {
        //display: none;
        text-align: center;
        align-items: center;
      }
    }
  }
  */
    .legal {
      display: flex;
      flex-direction: column;
      padding: $spacing-md;
      .text {
        font-size: $font-xs;
        //max-width: 31%;
      }
      @media (max-width: 768px) {
        // display: none;
        text-align: center;
        align-items: center;
      }
    }

    .socials-container {
      display: flex;
      flex-direction: column;
      padding: $spacing-md;

      .title {
        font-size: $font-xs;
        max-width: 31%;
      }
      .icons-wrapper {
        display: flex;
        flex-direction: row;
        .icon {
          width: 50px;
          height: 50px;
        }
      }
      @media (max-width: 768px) {
        align-items: center;
      }
    }

    .contacts {
      display: flex;
      flex-direction: column;
      padding: $spacing-md;

      .text {
        font-size: $font-xs;
      }
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
}
