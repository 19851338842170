@import "~@fontsource/manjari/index.css";

//Fonts
$Manjari: "Manjari";

// colors
$black: #1d1111;
$white: #ffffff;
$light-grey: #e8e7e7;
$grey: #d2d0cf;
$dark-grey: #a39f9f;
$text-black: #493e3e;

$green: #00b451;
$red: #ce0e2d;

$blue: #1ecad4;
$yellow: #ffc600;
$purple: #9760a7;
$orange: #ff671d;
$light-bg: #feeeed;

// spacing
$spacing-xs: 0.25em;
$spacing-sm: 0.5em;
$spacing-smd: 0.85em;
$spacing-md: 1em;
$spacing-ml: 1.5em;
$spacing-lg: 2em;
$spacing-xl: 3em;
$spacing-xxl: 3.5em;
$spacing-xxxl: 4.5em;

// font
$font-xs: 0.875rem;
$font-sm: 1rem;
$font-md: 1.25rem;
$font-lg: 1.5rem;
$font-lgr: 1.8rem;
$font-xlb: 2.3rem;
$font-xl: 2.5rem;
$font-xxl: 2.8rem;

// shadow
$shadow: 0 4px 7px 0 #0002;

// border
$border: 0.3px solid $light-grey;
$border-radius: 8px;

// header height
$headerHeight: 5rem;
