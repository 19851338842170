@import "./variables.scss";
@import "~@fontsource/manjari/index.css"; 

body {
  margin: 0;
  font-family: $Manjari;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100vh;
}

code {
  font-family: $Manjari;
}
