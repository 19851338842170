@import "../../styles/variables.scss";

.purple-item {
  width: max-content;
  height: max-content;
  margin-left: $spacing-md;
  margin-right: $spacing-md;
  margin-bottom: $spacing-xl;
  margin-top: $spacing-xl;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    margin-left: 0;
    padding-right: $spacing-md;
  }

  .purple-image {
    width: 375px;
    height: 180px;
    @media (max-width: 768px) {
    }
  }
  .arrow {
    display: block;
  }
  .slick-prev:before,
  .slick-next:before {
    background-color: $yellow !important;
  }
}
