@import "../../styles/variables.scss";

* {
  box-sizing: border-box;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;

  .bluebody {
    background-color: $blue;
    padding: 1em 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px;

    .subscription {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $white;
      gap: 1rem;
      height: 100%;

      .logosuccess {
        width: 300px;
        padding-bottom: $spacing-md;
      }

      .smallerfont {
        font-size: $font-md;
        color: $black;
        font-weight: bold;
        align-items: left;
      }
      .biggerfont {
        font-size: $font-xxl;
        color: $black;
        font-weight: 700;
      }
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  .whitebody {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 1em 1em;
    justify-content: center;
    align-items: center;
    border-left: solid 1px;

    .smallerfont {
      font-size: $font-md;
      color: $black;
      font-weight: bold;
      text-align: justify;
      padding: $spacing-lg $spacing-xxl $spacing-lg $spacing-xxl;
      @media (max-width: 768px) {
        padding: $spacing-lg $spacing-md $spacing-lg $spacing-md;
      }
    }
    .biggerfont {
      text-align: center;
      font-size: $font-xxl;
      color: $black;
      font-weight: 700;
      padding-bottom: $spacing-md;
    }
    .button {
      border-radius: $border-radius;
      border: 2px solid $black;
      width: 100%;
      padding: 0.8em 1.2em;
      font-size: $font-md;
      font-weight: bold;
      background-color: $yellow;
      color: $black;
      margin: $spacing-sm 0 $spacing-xxl 0;
      cursor: pointer;
      text-align: center;

      &:hover {
        background-color: $purple;
        color: $white;
      }
    }
    .logo {
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 100%;
      object-fit: contain;
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
  }
}
